.loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    display: grid;
    place-items: center;
    z-index: 9999;
    animation: fade-out 1s 500ms ease-out forwards;
}
.loader {
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slide {
    100% {
        opacity: 0;
        display: none;
    }
    80% {
        opacity: 10%;
    }
    60% {
        opacity: 40%;
    }
    40% {
        opacity: 100%;
    }
    20% {
        opacity: 100%;
    }
    0% {
        opacity: 100%;
    }
}
